header, .header {

  transition: all 200ms ease 0s;

  * {
    transition: all 200ms ease 0s;
  }

  a {
    text-decoration: none !important;
  }

  section {
    display: inline-block;
  }

  .header-shadow {
    display: none;
  }
}


.hero-header {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1000000;
  background: none;
  color: white;
  transition: transform 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;

  a {
    button {
      color: white;
    }
  }

  .header.fixed-header {
    background: none;
    color: white;
  }

  .header-shadow {
    display: inline-block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10em;
    z-index: -10;
    background-image: linear-gradient(to bottom, rgba(0,0,0,.5), rgba(100,100,100,0));
  }

  &.scrolled {
    background: white;
    border-bottom: 1px solid #a9a9a9;
    color: #333;
    
    .MuiToolbar-regular {
      min-height: 0;
      padding: .2em 1em;
    }

    .header-shadow {
      display: none;
    }

    a {
      button {
        color: #333;
      }
    }
  }
}