.media-drawer .MuiDrawer-paperAnchorBottom {
    min-height: 10vh;
    max-height: 80vh;
    padding: 2em;
    overflow-y: auto;
}

.media-drawer .close-link {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    z-index: 10;
    cursor: pointer;
}

.media-drawer .media-grid {
    margin: 1em 0;
}

.media-drawer .no-media-yet {
    min-height: 20vh;
    padding-top: 10vh;
    text-align: center;
    color: #9a9a9a;
    font-style: italic;
    font-weight: 300;
}