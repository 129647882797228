.dummy-card {
    background-color: #efefef !important;
    position: absolute;
    width: 90%;
    left: 10%;
    top: 0;
    height: 12em;
}

.image-link {
  cursor: pointer;
  position: relative;
}

.image-link * {
  transition: all 200ms ease 0s;
}
.image-link:hover * {
  opacity: 0.5;
}

