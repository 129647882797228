body {
  background-color: white;
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

.ReactModal__Overlay {
  z-index: 1200 !important;
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 56px !important;
}

.carousel.carousel-slider {
  height: 100%
}

.slider-wrapper {
  height: 100%;
}

ul.slider {
  height: 100%;
}

.carousel .slide {
  background-color: #e0e0e0 !important;
}

.car-section-nav {
  .auth-nav-link:hover {
    background-color: rgba(245, 0, 87, 0.08);
  }

  .MuiListItemIcon-root {
    min-width: 40px;
  }
}